import { useQuery } from '@tanstack/react-query';
import { Box } from '@xtreamsrl/react-ui-kit/Box';
import { Button } from '@xtreamsrl/react-ui-kit/Button';
import { Checkbox } from '@xtreamsrl/react-ui-kit/Checkbox';
import { Flex } from '@xtreamsrl/react-ui-kit/Flex';
import { Modal } from '@xtreamsrl/react-ui-kit/Modal';
import { Typography } from '@xtreamsrl/react-ui-kit/Typography';
import React from 'react';
import { toMoreDescriptiveName } from '../../../_shared/utils/utils';
import { optionsBuilder } from '../../asyncHooks/getRequestInfo';
import { useRequestId } from '../../hooks/useRequestId';
import { useAcceptTermsMutations } from '../../mutations/acceptTermsMutation';
import { PlatformBox } from '../BoxVariants';
import { DocumentFilterDisplay } from '../DocumentFilterDisplay';
import FullScreenCard from '../FullScreenCard';

type Props = {
  nextStep: (value: boolean) => void;
};

const RequestInfo: React.FC<Props> = ({ nextStep }) => {
  const requestId = useRequestId();
  const { data: request } = useQuery({
    ...optionsBuilder(requestId),
    select: (data) => ({
      target: data.target,
      requestFrom: data.requestFrom,
      platformAndDocuments: data.platformAndDocuments,
      purpose: data.purpose,
    }),
  });
  if (!request) throw new Error('Request is required');
  const [authorised, setAuthorised] = React.useState(false);
  // have not used isError from the mutation because otherwise I could not control the modal closure after the error
  const {
    mutate,
    isPending: isTermAcceptancePending,
    isError,
    reset,
  } = useAcceptTermsMutations({
    onSuccess: () => {
      nextStep(true);
    },
    onError: () => {
      console.error('Failure during terms acceptance');
    },
  });

  return (
    <>
      <Modal
        open={isError}
        onClose={() => {
          reset();
        }}
      >
        <Modal.Content>
          <Box paddingTop="md-1">
            <Typography variant="body/lg/semibold">
              Errore durante l'invio di accettazione dei termini
            </Typography>
            <Typography variant="body/sm/regular">
              Si è verificato un problema durante il processo di accettazione
              dei termini e condizioni. Ti preghiamo di riprovare.
            </Typography>
          </Box>
          <Modal.Footer type="right">
            <Modal.PrimaryAction
              onClick={() => {
                reset();
                mutate(requestId);
              }}
            >
              Riprova
            </Modal.PrimaryAction>
            <Modal.SecondaryAction onClick={() => reset()}>
              Chiudi
            </Modal.SecondaryAction>
          </Modal.Footer>
        </Modal.Content>
      </Modal>
      <FullScreenCard>
        <Flex
          direction="column"
          justifyContent="space-between"
          height="100%"
          gap="md-5"
        >
          <img
            alt={`Logo ${request.requestFrom.name}`}
            style={{
              height: '40px',
              objectFit: 'contain',
            }}
            src={request.requestFrom.logo}
          />
          <Typography variant="body/base/medium" textAlign="center">
            {request.target.type === 'PF'
              ? 'Accedendo a questo servizio trasmetti a'
              : `Accedendo a questo servizio autorizzi ${request.target.name} a trasmettere a`}{' '}
            {request.requestFrom.name} le seguenti informazioni necessarie per{' '}
            {request.purpose}:
          </Typography>

          <Flex direction="column" gap="md-8">
            {request.platformAndDocuments.map((platformDocs) => (
              <PlatformBox
                platform={platformDocs.platform}
                key={platformDocs.platform}
              >
                <ul>
                  <Flex direction="column" gap="sm-4">
                    {platformDocs.documents.map((doc) => (
                      <li key={doc.type}>
                        <Flex
                          alignItems={
                            doc.type === 'F24_RIEPILOGATIVO'
                              ? 'left'
                              : 'baseline'
                          }
                          direction={
                            doc.type === 'F24_RIEPILOGATIVO' ? 'column' : 'row'
                          }
                          gap="sm-4"
                        >
                          <Typography variant="body/base/semibold">
                            {toMoreDescriptiveName(doc.type)}
                          </Typography>
                          <DocumentFilterDisplay document={doc} />
                        </Flex>
                      </li>
                    ))}
                  </Flex>
                </ul>
              </PlatformBox>
            ))}
          </Flex>

          {/* parte sotto ai documenti */}
          <Flex direction="column" gap="md-2">
            <Flex direction="row" alignItems="flex-start" gap="sm-6">
              <Checkbox
                checked={authorised}
                onChange={() => setAuthorised((p) => !p)}
              />

              <Typography variant="body/xs/regular" color="grey.11">
                Dichiaro di aver letto e compreso l'
                <a
                  href={request.requestFrom.privacyUrl}
                  target="_blank"
                  style={{ textDecoration: 'none', display: 'inline-block' }}
                >
                  <Typography color="brand" variant="body/xs/black">
                    informativa sulla privacy
                  </Typography>
                </a>
                , confermando di essere a conoscenza delle modalità di
                trattamento dei dati e accettando quanto previsto dalla
                normativa vigente
              </Typography>
            </Flex>

            <Flex direction="row" justifyContent="center">
              <Button
                disabled={!authorised}
                style={{
                  flex: 0.2,
                  flexShrink: 0,
                }}
                loading={isTermAcceptancePending}
                variant="outlined"
                color="brand"
                size="lg"
                onClick={() => mutate(requestId)}
              >
                Avanti
              </Button>
            </Flex>
          </Flex>
          {/*  end of footer */}
        </Flex>
        {/* end of card content */}
      </FullScreenCard>
    </>
  );
};

RequestInfo.displayName = 'RequestInfo';

export default RequestInfo;
